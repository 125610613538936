<template>
  <a-input
    v-model="floor"
    v-bind="$attrs"
    v-on:keyup.enter="$emit('enter')"
    size="large"
    allow-clear>
    <a-icon slot="prefix" type="number" style="color: rgba(0,0,0,.25)" />
  </a-input>
</template>

<script>
export default {
  name: 'Floor',
  props: ['value'],
  computed: {
    floor: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value.toUpperCase())
      }
    },
  },
}
</script>
