<template>
  <a-input
    v-model="name"
    v-bind="$attrs"
    v-on:keyup.enter="$emit('enter')"
    size="large"
    allow-clear>
    <a-icon
      slot="prefix"
      type="user"
      style="color: rgba(0,0,0,.25)" />
  </a-input>
</template>

<script>
export default {
  name: 'Name',
  props: ['value'],
  computed: {
    name: {
      get() {
        return this.value
      },
      set(value) {
        const capitalized = this.capitalize(value)
        this.$emit('input', capitalized)
        this.$emit('change', capitalized)
      }
    },
  },
  methods: {
    capitalize(value) {
      return value
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
  },
}
</script>
