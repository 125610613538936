import { isValidPhoneNumber } from 'libphonenumber-js'

const name = (rule, value, callback) => {
  if (!value) {
    callback(new Error('El nombre es requerido'))
  } else {
    callback()
  }
}

const origPhone = (rule, value, callback) => {
  if (!value) {
    callback(new Error('El teléfono es requerido'))
  } else if (!isValidPhoneNumber(value, 'AR')) {
    callback(new Error('El teléfono no es válido'))
  } else {
    callback()
  }
}

const destPhone = (rule, value, callback) => {
  if (value && !isValidPhoneNumber(value, 'AR')) {
    callback(new Error('El teléfono no es válido'))
  } else {
    callback()
  }
}

const address = (rule, value, callback) => {
  if (!value) {
    callback(new Error('La dirección es requerida'))
  } else if (!value.number) {
    callback(new Error('La dirección está incompleta'))
  } else {
    callback()
  }
}

const email = (rule, value, callback) => {
  if (!value) {
    callback(new Error('El email es requerido'))
  } else if(!isValidEmail(value)) {
    callback(new Error('El email no es válido'))
  } else {
    callback()
  }
}

const isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export default {
  origin: {
    address: [
      { validator: address, trigger: 'change' },
    ],
    name: [
      { validator: name, trigger: 'change' },
    ],
    phone: [
      { validator: origPhone, trigger: 'change' },
    ],
    email: [
      { validator: email, trigger: 'change' },
    ],
  },
  destination: {
    address: [
      { validator: address, trigger: 'change' },
    ],
    name: [
      { validator: name, trigger: 'change' },
    ],
    phone: [
      { validator: destPhone, trigger: 'change' },
    ],
  },
}