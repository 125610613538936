import Vue from 'vue'
import Moment from 'moment'

Vue.filter('calendar', value => {
  if (value) {
    return Moment(value)
      .calendar(null, {
        sameDay: '[hoy]',
        nextDay: '[mañana]',
        nextWeek: '[el] dddd DD/MM',
        sameElse: 'L'
      })
  }
})

Vue.filter('city', address => {
  if (address) {
    return address.city
      ? `${address.city}, ${address.state}`
      : address.state
  }
})
