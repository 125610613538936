<template>
  <router-view />
</template>

<script>
import Vue from 'vue'

export default {
  name: 'App',
  metaInfo () {
    return {
      script: [
        {
          src: `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_API_KEY}&libraries=places`,
          async: true,
          defer: true,
          callback: () => this.onGooglePlacesLoad()
        },
        {
          src: `https://sdk.mercadopago.com/js/v2`,
          async: true,
          defer: true,
          callback: () => this.onMercadoPagoLoad()
        },
      ]
    }
  },
  methods: {
    onGooglePlacesLoad () {
      Vue.prototype.$geocoder = new window.google.maps.Geocoder();
      Vue.prototype.$autocomplete = new window.google.maps.places.AutocompleteService()
    },
    onMercadoPagoLoad () {
      Vue.prototype.$mp = new window.MercadoPago(process.env.VUE_APP_MPAGO_API_KEY, { locale: 'es-AR' })
    },
  },
}
</script>
