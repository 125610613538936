<template>
  <a-result
    title="Validando el link"
    sub-title="Esto puede demorar unos segundos."
    v-if="result === 'loading'">
    <template #icon>
      <a-icon type="loading" />
    </template>
  </a-result>
  <a-result
    status="error"
    title="Link inválido"
    sub-title="Parece que el link que utilizaste es inválido o ya expiró."
    v-else-if="result === 'rejected'">
    <template #extra>
      <a-button
        shape="round"
        type="primary"
        size="large"
        @click="retry">
        Reintentar
      </a-button>
    </template>
  </a-result>
</template>

<script>
export default {
  data: function () {
    return {
      result: 'loading',
    }
  },
  mounted() {
    if (this.code) {
      this.fetch()
    } else {
      this.result = 'rejected'
    }
  },
  computed: {
    code() {
      return new URLSearchParams(window.location.search).get('code');
    },
  },
  methods: {
    retry() {
      location.reload()
    },
    fetch() {
      const params = { code: this.code }
      this.$http.get('/self-service/templates', { params })
      .then(response => {
        this.$store.commit('SET_CODE', this.code)
        this.$store.commit('SET_FEATURES', response.data)
        this.$router.push('/form')
      })
      .catch(() => {
        this.result = 'rejected'
      })
    },
  }
}
</script>
