import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '@/views/auth.vue'
import Form from '@/views/form.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/form',
    name: 'Form',
    component: Form
  },
  {
    path: '*',
    component: Auth,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
