<template>
  <a-input
    v-model="phone"
    v-bind="$attrs"
    v-on:keyup.enter="$emit('enter')"
    inputmode="numeric"
    pattern="[0-9]*"
    size="large"
    allow-clear>
    <template #prefix>
      <img width="12" alt="ar"
        src="https://flagcdn.com/w20/ar.png"
        srcset="https://flagcdn.com/w40/ar.png 2x"/>
    </template>
    <!-- <a-icon slot="prefix" type="phone" style="color: rgba(0,0,0,.25)" /> -->
  </a-input>
</template>

<script>
import { AsYouType } from 'libphonenumber-js'

export default {
  name: 'Phone',
  props: ['value'],
  computed: {
    phone: {
      get() {
        if (this.value) {
          const parser = new AsYouType('AR')
          const pretty = parser.input(this.value)
          return pretty
        }
        return null
      },
      set(value) {
        const parser = new AsYouType('AR')
        parser.input(value)
        if (parser.getNumber()) {
          this.$emit('input', parser.getNumber().number)
          this.$emit('change', parser.getNumber().number)
        } else {
          this.$emit('input', null)
          this.$emit('change', null)
        }
      }
    },
  },
}
</script>
