<template>
  <a-input
    v-model="email"
    v-bind="$attrs"
    v-on:keyup.enter="$emit('enter')"
    size="large"
    allow-clear>
    <a-icon
      slot="prefix"
      type="mail"
      style="color: rgba(0,0,0,.25)" />
  </a-input>
</template>

<script>
export default {
  name: 'Email',
  props: ['value'],
  computed: {
    email: {
      get() {
        return this.value ? this.value.toLowerCase() : null
      },
      set(value) {
        this.$emit('input', value)
        this.$emit('change', value)
      }
    },
  },
  methods: {
    isValid(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
  },
}
</script>
