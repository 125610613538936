import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    code: null,
    features: null,
  },
  mutations: {
    SET_CODE(state, payload) {
      state.code = payload
    },
    SET_FEATURES(state, payload) {
      state.features = payload
    },
  },
  actions: {},
})
