import Vue from 'vue'
import App from './app.vue'
import store from './store'
import './filters'

// Ant Design Vue
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import "@/assets/global.css"

// Vue Meta
import Meta from 'vue-meta'

// Vue Axios
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

Vue.use(VueAxios, http)

Vue.config.productionTip = false

Vue.use(Antd)
Vue.use(Meta)

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
